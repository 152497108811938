import React, { useContext } from 'react'
import { EnergyContext } from '../../context/EnergyContext'
import { msg } from '../../locale/texts'
import { Step } from '../Step'
import { FuelEntry } from './FuelEntry'
import { Grant } from './Grant'

export const Step3 = () => {
  const { fuelEntries, addEnergyEntry, updateFuelEntry } = useContext(EnergyContext)

  const prevStep = {
    msg: msg.links.backStep2,
    link: '/step2',
    right: true
  }

  const nextStep = {
    msg: msg.links.nextStep4,
    link: '/step4',
  }
  return (
    <Step title={msg.step3.title} text={msg.step3.text} prevStep={prevStep} nextStep={nextStep}>

      {fuelEntries &&
        fuelEntries.map((fuelEntry, id: number) =>
          <div key={`step-3-${id}`} >
            <FuelEntry id={id} entry={fuelEntry} updateFuelEntry={updateFuelEntry} />
          </div>)
      }

      <Grant />
    </Step>
  )
}