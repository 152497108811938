import React, { useContext, useEffect, useMemo, useState } from 'react'
import { EnergyContext } from '../../../context/EnergyContext'
import { msg } from '../../../locale/texts'
import { exportDataToJSON, formatNumber, printNumber } from '../../../utils'
import { Summary } from '../../Summary'
import { SummaryHeader } from '../../Summary/SummaryHeader'
import { SummaryRow } from '../../Summary/SummaryRow'
import { SummarySection } from '../../Summary/SummarySection'

type Grant = {
    energy: number
    price: number
    name: string
    average: string
}

export const Grant = () => {
    const { entries, fuelEntries } = useContext(EnergyContext)
    const [total, setTotal] = useState(0)
    let exportData: Record<string, any> = {}

    const grants = useMemo(() => {
        const tempEnergies: any[] = []

        let fuels = 0

        fuelEntries.map(fuelEntry => {
            fuels += Math.max(formatNumber(fuelEntry.consumption) * (formatNumber(fuelEntry.averagePrice) / 1.2 - formatNumber(fuelEntry.constant) - 0.6), 0)
        })

        tempEnergies.push({
            amount: formatNumber(formatNumber(fuels) * 0.3).toFixed(2),
            text: msg.grant.fuelText,
            name: msg.fuelTypes.title,
        })

        return tempEnergies

    }, [entries, fuelEntries])

    useEffect(() => {
        const temp_total = parseFloat((grants.reduce((t, curr) => t += formatNumber(curr.amount), 0)).toFixed(2))
        setTotal(temp_total > 2000 ? Math.min(temp_total, 400000) : 0)
    }, [grants])

    return (
        <div className='gap-x-8 text-right'>
            <Summary>
                <SummarySection >

                    <SummaryHeader>
                        <p className='w-full text-left'>{msg.fuelTypes.title}</p>
                    </SummaryHeader>

                    {grants &&
                        grants.map((grant) =>
                            <SummaryRow
                                key={`grant-${grant.name}`}
                                title={`${grant.name}`}
                                text={`${grant.text}`}
                            >
                                <span className={`appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline font-bold`}>
                                    {printNumber(grant.amount)} <span className='text-sm'>EUR</span>
                                </span>
                            </SummaryRow>)
                    }
                </SummarySection>

            </Summary>

        </div>
    )
}
