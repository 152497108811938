import React, { FC, useEffect, ChangeEvent, useState } from 'react'
import { FuelEntry as FuelEntryType } from '../../../@types'
import { FuelEntryAverageBruttoPrice } from './FuelEntryAverageBruttoPrice'
import { FuelEntryConsumption } from './FuelEntryConsumption'
import { FuelEntryAverageNettoPrice } from './FuelEntryAverageNettoPrice'

type FuelEntryProps = {
    id: number
    entry: FuelEntryType
    updateFuelEntry: (id: number, entry: FuelEntryType) => void
}

export const FuelEntry: FC<FuelEntryProps> = ({ id, entry, updateFuelEntry }) => {

    const [updatedEntry, setUpdatedEntry] = useState(entry)

    const handleChangeEvent = (e: ChangeEvent<any>) => {
        if (e && e.target) {
            setUpdatedEntry(oldEntry => { return { ...oldEntry, [e.target.name]: e.target.value } })
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            updateFuelEntry(id, updatedEntry)
        }, 200)

        return () => clearTimeout(timer)
    }, [updatedEntry])


    return (
        <div className='card my-3 p-4 rounded-2xl md:text-white border border-primary-500'>
            <p className='font-semibold'>{entry.name}</p>
            <FuelEntryConsumption id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            <FuelEntryAverageBruttoPrice id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            <FuelEntryAverageNettoPrice id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
        </div>
    )
}
