import React, { ChangeEvent, FC } from 'react'
import { FuelEntryPropEnum } from '../../../@enums'
import { FuelEntry as FuelEntryType } from '../../../@types'
import { msg } from '../../../locale/texts'
import { Entry } from '../../Entry'
import { Input } from '../../Input'
import { formatNumber, printNumber } from '../../../utils'

type FuelEntryAverageNettoPriceProps = {
    entry: FuelEntryType
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}

export const FuelEntryAverageNettoPrice: FC<FuelEntryAverageNettoPriceProps> = ({ entry, id, handleChangeEvent }) => {
    const property = FuelEntryPropEnum.AVERAGE_PRICE

    return (
        <Entry
            title={`${msg.fuelEntry.averagePriceNetto}`}
            text={`${msg.fuelEntry.averageNettoPriceText}`}
        >
            <Input
                id={`fuel${id}Netto${property}`}
                disabled={true}
                name={property}
                value={printNumber(
                    Math.max((formatNumber(entry[property], 4) / 1.2) - entry.constant
                        , 0), 3)
                }
                onChange={handleChangeEvent}
            />
        </Entry>
    )
}
