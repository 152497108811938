import React, { ChangeEvent, FC } from 'react'
import { FuelEntryPropEnum } from '../../../@enums'
import { FuelEntry as FuelEntryType } from '../../../@types'
import { EnergyContext } from '../../../context/EnergyContext'
import { msg } from '../../../locale/texts'
import { formatNumber } from '../../../utils'
import { Entry } from '../../Entry'
import { Input } from '../../Input'

type FuelEntryConsumptionProps = {
    entry: FuelEntryType
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}

export const FuelEntryConsumption: FC<FuelEntryConsumptionProps> = ({ entry, id, handleChangeEvent }) => {
    const property = FuelEntryPropEnum.CONSUMPTION

    return (
        <Entry
            title={`${entry.name}${msg.fuelEntry.consumption}`}
            text={`${msg.fuelEntry.consumptionText}`}
        >
            <Input
                type="number"
                id={`fuel${id}${property}`}
                name={property}
                value={
                    entry[property]
                        ? entry[property]
                        : undefined
                }
                decimals={0}
                onChange={handleChangeEvent}
                step={1}
            />
        </Entry>
    )
}
