import React, { ChangeEvent, FC } from "react";
import { FuelEntryPropEnum } from "../../../@enums";
import { FuelEntry as FuelEntryType } from "../../../@types";
import { msg } from "../../../locale/texts";
import { Entry } from "../../Entry";
import { Input } from "../../Input";
import { formatNumber } from "../../../utils";

type FuelEntryAverageBruttoPriceProps = {
  entry: FuelEntryType;
  id: number;
  handleChangeEvent: (e: ChangeEvent<any>) => void;
};

export const FuelEntryAverageBruttoPrice: FC<
  FuelEntryAverageBruttoPriceProps
> = ({ entry, id, handleChangeEvent }) => {
  const property = FuelEntryPropEnum.AVERAGE_PRICE;

  let text = `${msg.fuelEntry.averageBruttoPriceText}`;
  let textClassName = undefined;

  if (
    entry[property] &&
    formatNumber(entry[property]) <= entry[FuelEntryPropEnum.PRICE_CONSTANT]
  ) {
    text += `<span class="text-red-700">${msg.fuelEntry.averageBruttoPriceTextError1} ${entry.name}${msg.fuelEntry.averageBruttoPriceTextError2}</span>`;
    textClassName = `flex-col items-start`;
  }

  return (
    <Entry
      title={`${msg.fuelEntry.averagePriceBrutto}`}
      text={text}
      textClassName={textClassName}
    >
      <Input
        type="number"
        id={`fuel${id}${property}`}
        name={property}
        value={entry[property] ? entry[property] : undefined}
        decimals={3}
        onChange={handleChangeEvent}
        step={0.001}
      />
    </Entry>
  );
};
